<template>
  <div class="section">
    <slot name="header" v-if="$slots['header']"></slot>
    <slot></slot>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/section.scss';
</style>
