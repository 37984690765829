<template>
  <div class="section section-prefetch" :class="customClass">
    <div class="animated-background" :style="{'height': unitPixel(options.height) }">
      <div class="background-masker header-top"></div>
      <div class="background-masker header-bottom"></div>
      <div class="background-masker header-right"></div>
      <div class="background-masker content-split" v-if="options.enabledTwoColumns"></div>
      <div class="background-masker content-bottom" :style="{'height': calContentBottomHeight(options.height) }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({ height: '400' }),
    },
    customClass: {
      type: String,
      default: '', // two-columns
    },
  },
  methods: {
    unitPixel(value) {
      return `${value}px`;
    },
    calContentBottomHeight(height) {
      if (height) {
        return this.unitPixel(height / 2);
      }
      return '100px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/section.scss';


.section-prefetch {
  padding: $spacer $spacer * 1.25;
}

@keyframes placeHolderShimmer {
  0%{ background-position: -50% 0; }
  100%{ background-position: 50% 0; }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #efefef, #eaeaea, #efefef);
  background-size: 50% 104px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top {
  top: 0;
  left: 0;
  right: 0;
  height: $spacer;
}

.background-masker.header-bottom {
  top: $spacer * 3;
  left: 0;
  right: 0;
  height: 30px;
}

.background-masker.header-right {
  top: 0;
  left: 50%;
  right: 0;
  height: 60px;
}

.background-masker.content-split {
  top: 70px;
  left: 35%;
  right: 0;
  width: 32px;
  height: 440px;
}

.background-masker.content-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}


@media (max-width: 575.98px) {
 .section.section-prefetch {
    padding: $spacer $spacer * 1.25;
    .animated-background {
      height: 300px !important;
      .background-masker.content-bottom {
        height: 0px !important;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
      }
    }

    &.two-columns {
      .animated-background {
        .background-masker.content-split {
          top: 145px;
          left: 0;
          width: 100%;
          height: 30px;
          bottom: auto;
        }
      }
    }
  }
}


</style>
