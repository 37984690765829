const abbreviateLongNumbers = (n) => {
  if (n < 1e3) return parseFloat(n.toFixed(1));
  if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${(n / 1e9).toFixed(1)}T`;
  return n;
};

const transformUnit = (count) => {
  let formattedText;
  switch (true) {
    case count < 9999:
      if (count < 999) {
        formattedText = count;
      } else {
        formattedText = Number(count).toLocaleString('th-TH');
      }
      break;
    case count >= 9999 && count < 999999:
      if ((Math.floor(count / 1000)).toString().length > 3) {
        formattedText = `${(count / 1000)}K`;
      } else {
        formattedText = `${(count / 1000).toFixed(2)}K`;
      }
      break;
    case count >= 999999 && count < 999999999:
      if ((Math.floor(count / 100000000)).toString().length > 3) {
        formattedText = `${(count / 100000000)}M`;
      } else {
        formattedText = `${(count / 1000000).toFixed(2)}M`;
      }
      break;
    case count >= 999999999:
      if ((Math.floor(count / 1000000000)).toString().length > 3) {
        formattedText = `${(count / 1000000000)}B`;
      } else {
        formattedText = `${(count / 1000000000).toFixed(2)}B`;
      }
      break;
    default:
      formattedText = '0';
      break;
  }
  return formattedText;
};

const transformUnitWithoutDecimal = (count) => {
  let formattedText;
  switch (true) {
    case count < 9999:
      if (count < 999) {
        formattedText = count;
      } else {
        formattedText = Number(count).toLocaleString('th-TH');
      }
      break;
    case count >= 9999 && count < 999999:
      if ((Math.floor(count / 1000)).toString().length > 3) {
        formattedText = `${(count / 1000)}K`;
      } else {
        formattedText = `${(count / 1000)}K`;
      }
      break;
    case count >= 999999 && count < 999999999:
      if ((Math.floor(count / 100000000)).toString().length > 3) {
        formattedText = `${(count / 100000000)}M`;
      } else {
        formattedText = `${(count / 1000000)}M`;
      }
      break;
    case count >= 999999999:
      if ((Math.floor(count / 1000000000)).toString().length > 3) {
        formattedText = `${(count / 1000000000)}B`;
      } else {
        formattedText = `${(count / 1000000000)}B`;
      }
      break;
    default:
      formattedText = '0';
      break;
  }
  return formattedText;
};

const numberWithCommas = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export {
  abbreviateLongNumbers,
  transformUnit,
  numberWithCommas,
  transformUnitWithoutDecimal,
};
