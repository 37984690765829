const getTableHeaderByName = (name) => {
  switch (name) {
    case 'rank':
      return 'Rank';

    case 'stationName':
      return 'Station';

    case 'title':
      return 'Program';

    case 'web':
      return 'Web';

    case 'app':
      return 'App';

    case 'official':
      return 'Official Platform';

    case 'officialPlatform':
      return 'Official Platform';

    case 'trueId':
      return 'TrueID';

    case 'totalViewCount':
      return 'Total';

    default:
      return '';
  }
};

const prettyStationName = (stationName) => {
  switch (stationName) {
    case 'ch3Plus':
      return 'CH 3+';

    default:
      return stationName;
  }
};

const getStationName = (name) => {
  if (name === 'ch3Plus') {
    return 'CH 3 + (My Station)';
  }
  return 'CH 3 + (My Station)';
};

const getTableHeaderColorByName = (name) => {
  switch (name) {
    case 'web':
      return '#73E600';

    case 'app':
      return '#EB8D00';

    case 'trueId':
      return '#D80000';

    default:
      return 'transparent';
  }
};

export {
  getTableHeaderByName,
  prettyStationName,
  getTableHeaderColorByName,
  getStationName,
};
