import {
  differenceInMilliseconds as fnsDiff,
  format,
} from 'date-fns';

const formatTableDate = (startDate, endDate) => `${format(new Date(startDate), 'dd/MM/yyyy')} เวลา ${format(new Date(startDate), 'HH:mm')} - ${format(new Date(endDate), 'HH:mm')}`;
const transformDateToDisplay = date => format(new Date(date), 'dd/MM/yyyy');
const transformDateToDisplayTime = date => format(new Date(date), 'HH:mm');
const formatDate = date => format(new Date(date), 'yyyy-MM-dd');
const formatDateTime = date => format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
const formatGraphTime = date => format(new Date(date), 'yyyy-MM-dd');
const formatTimeDate = date => format(new Date(date), 'HH:mm:ss dd/MM/yyyy ');
const formatLongDate = date => format(new Date(date), 'HH:mm:ss @ MMM d, yyyy');
const formatLongDateTime = date => format(new Date(date), 'HH:mm:ss @ MMM d, yyyy');
const formatLongDateTimeWithMs = date => format(new Date(date), 'HH:mm:ss.SSS @ MMM d');
const formatLongDateOnly = date => format(new Date(date), 'MMM d, yyyy');
const formatLongTime = date => format(new Date(date), 'HH:mm:ss');
const formatLongTimeWithMs = date => format(new Date(date), 'HH:mm:ss.SSS');
const formatShortDate = date => format(new Date(date), 'd MMM');
const formatTime = date => format(new Date(date), 'HH:mm');
const differenceInMilliseconds = (dateLeft, dateRight) => fnsDiff(dateLeft, dateRight);
const transformTime = (seconds) => {
  let formattedSecond;
  switch (true) {
    case seconds < 60:
      formattedSecond = seconds;
      break;
    case seconds > 60 && seconds < 3600:
      formattedSecond = `${Math.floor(seconds / 60)} M.`;
      break;
    default:
      formattedSecond = '0 Second';
      break;
  }
  return formattedSecond;
};
const transformTimeCode = (rawSeconds) => {
  const hours = Math.floor(rawSeconds / 3600);
  const minutes = Math.floor((rawSeconds / 60) % 60);
  const seconds = Math.floor(rawSeconds % 60);
  const milliSeconds = Math.floor((rawSeconds * 1000) % 1000);

  let hoursString = '0';
  if (hours > 0) {
    hoursString = `${hours}`;
  }

  let minutesString = '00';
  if (minutes >= 10) {
    minutesString = `${minutes}`;
  } else if (minutes > 0) {
    minutesString = `0${minutes}`;
  }

  let secondsString = '00';
  if (seconds >= 10) {
    secondsString = `${seconds}`;
  } else if (minutes > 0) {
    secondsString = `0${seconds}`;
  }

  let milliSecondsString = '000';
  if (milliSeconds >= 100) {
    milliSecondsString = `${milliSeconds}`;
  } else if (milliSeconds >= 10) {
    milliSecondsString = `0${milliSeconds}`;
  } else if (milliSeconds > 0) {
    milliSecondsString = `00${milliSeconds}`;
  }

  return `${hoursString}:${minutesString}:${secondsString}.${milliSecondsString}`;
};

export {
  formatTableDate,
  transformDateToDisplay,
  transformDateToDisplayTime,
  formatDate,
  formatDateTime,
  formatGraphTime,
  formatTimeDate,
  formatLongDate,
  formatLongDateTime,
  formatLongDateTimeWithMs,
  formatLongDateOnly,
  formatLongTime,
  formatLongTimeWithMs,
  formatShortDate,
  formatTime,
  differenceInMilliseconds,
  transformTime,
  transformTimeCode,
};
