<template>
  <div :class="{
      'date-picker-modal': isModalOpen,
      'dark': dark,
    }">
    <div
      v-if="customTimePeriod"
      class="preset">
      <router-link
        :to="presentLinkFunction(range)"
        :class="`${dark ? 'preset-dark' : 'preset-item'} ${range.key} ${isRangeDateSelected(range) ? 'active' : ''}`"
        v-for="(range, index) in customTimePeriod"
        :key="index">
        {{range.name}}
      </router-link>
    </div>
    <date-range-picker
      ref="picker"
      :class="{'active': isCustomRangeDateActive() }"
      :singleDatePicker="enableSingleDatePicker"
      :dateRange="dateRange"
      :startDate="dateRange.startDate"
      :endDate="dateRange.endDate"
      :locale-data="locale"
      :autoApply="false"
      :ranges="false"
      @update="updateValues"
      @toggle="onToggleDatePicker"
      :opens="opens"
      :dateFormat="dateFormat">
      <template v-slot:input>
        <i class="fas fa-calendar-alt icon"></i>
        <span class="date" v-if="isCustomRangeDateActive() && !enableSingleDatePicker">
          {{transformDateToDisplay(dateRange.startDate)}} - {{transformDateToDisplay(dateRange.endDate)}}
        </span>
        <span class="date" v-if="isCustomRangeDateActive() && enableSingleDatePicker && isSameDay">
          {{transformDateToDisplay(isCustomRangeDateActive())}}
        </span>
        <span class="date" v-if="isCustomRangeDateActive() && enableSingleDatePicker && !isSameDay">
          {{transformDateToDisplay(dateRange.startDate)}} - {{transformDateToDisplay(dateRange.endDate)}}
        </span>
        <span
          class="date date-preset-mobile"
          v-if="!isCustomRangeDateActive() && getCurrentPresetName()">
          {{ getCurrentPresetName() }}
        </span>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  differenceInDays,
  isAfter,
  format,
  addDays,
  isSameDay,
} from 'date-fns';
import DateRangePicker from 'vue2-daterange-picker';
import {
  formatDate,
  transformDateToDisplay,
} from '@/utils/dateFormatter';

export default {
  props: {
    customTimePeriod: {
      type: Array,
    },
    dateRange: {
      type: Object,
    },
    onChange: {
      type: Function,
    },
    presentLinkFunction: {
      type: Function,
    },
    disabledCustomDate: {
      type: Boolean,
    },
    range: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    enableSingleDatePicker: {
      type: [Boolean, String],
      default: false,
    },
    forcePresetLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DateRangePicker,
  },
  data() {
    return {
      opens: 'left',
      locale: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'ยืนยัน',
        cancelLabel: 'ยกเลิก',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        firstDay: 1,
        showWeekNumbers: true,
      },
      defaultRangeDate: null,
      isModalOpen: false,
    };
  },
  watch: {
    isModalOpen() {
      const className = 'modal-opened';
      if (this.isModalOpen) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    },
  },
  created() {
    this.defaultRangeDate = _.head(this.customTimePeriod);
  },
  computed: {
    isSameDay() {
      return isSameDay(new Date(this.dateRange.endDate), new Date(this.dateRange.startDate));
    },
  },
  methods: {
    formatDate,
    transformDateToDisplay,
    getCurrentPresetName() {
      const { range } = this.$route.query;
      const currentRange = _.find(this.customTimePeriod, item => item.key === range);
      if (currentRange) {
        return currentRange.name;
      }
      return this.forcePresetLabel ? 'Last 24 Hours' : '';
    },
    isCustomRangeDateActive() {
      return this.dateRange.startDate && this.dateRange.endDate;
    },
    dateFormat(classes, date) {
      const disabled = isAfter(new Date(date), new Date());
      return { ...classes, disabled };
    },
    onToggleDatePicker(isModalOpen) {
      this.isModalOpen = isModalOpen;
    },
    updateValues(data) {
      if (this.range) {
        const startDate = this.formatDate(data.startDate);
        const endDate = this.formatDate(data.endDate);
        const dateStart = new Date(startDate);
        const dateEnd = new Date(endDate);
        const diff = differenceInDays(dateEnd, dateStart);
        if (diff > 1) {
          const newEndDate = format(addDays(dateStart, 1), 'yyyy-MM-dd');
          this.onChange({ startDate, endDate: newEndDate });
        } else {
          this.onChange({ startDate, endDate });
        }
      } else {
        const startDate = this.formatDate(data.startDate);
        const endDate = this.formatDate(data.endDate);
        this.onChange({ startDate, endDate });
      }
    },
    isRangeDateSelected(rangeDate) {
      if (
        !this.dateRange.range && !this.dateRange.startDate && !this.dateRange.endDate
      ) {
        return rangeDate.key === this.defaultRangeDate.key;
      }

      if (!this.dateRange.startDate && !this.dateRange.endDate) {
        return rangeDate.key === this.dateRange.range;
      }
      return this.dateRange.range === rangeDate.key;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vue2-daterange-picker/dist/vue2-daterange-picker.css';
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/date-picker-light.scss';
@import '~@/assets/scss/themes/components/date-picker-dark.scss';

</style>
