<template>
  <Section class="line-chart-container">
    <div
      v-if="title"
      class="section-header"
      slot="header">
      <h1 class="title">{{title}}</h1>
    </div>
    <div
      v-if="!hideTime"
      class="time">
      {{getTimeString()}}
    </div>
    <div class="chart-container">
      <VueApexCharts
        ref="lineChart"
        height="400"
        type="area"
        :options="options"
        :series="series" />
    </div>
  </Section>
</template>

<script>
import {
  abbreviateLongNumbers,
  transformUnit,
  transformUnitWithoutDecimal,
  numberWithCommas,
} from '@/utils/numberUtils';
import { transformDateToDisplay } from '@/utils/dateFormatter';

import VueApexCharts from 'vue-apexcharts';
import Section from '@/components/Section.vue';
import _ from 'lodash';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Top 10 Stations',
    },
    categories: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: false,
      default: () => ['#2CC84E', '#D87400', '#D80000', '#1C86E8', '#157E2C', '#8143D0', '#93725F', '#1C4A74', '#D96ED4', '#B1B1B1'],
    },
    series: {
      type: Array,
      required: true,
    },
    hideTime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Section,
    VueApexCharts,
  },
  data() {
    return {
      options: {
        chart: {
          animations: {
            enabled: false,
          },
          fontFamily: 'Helvetica, Noto Sans Thai, Arial, sans-serif',
          id: 'line-chart',
          height: 400,
          redrawOnParentResize: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            click: (event, chartContext, config) => {
              const index = config.dataPointIndex;
              const timestamp = config.config.xaxis.categories[index];
              this.$emit('lineClicked', new Date(timestamp));
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: undefined,
          opacity: 0,
          type: 'solid',
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        colors: this.colors,
        tooltip: {
          x: {
            show: false,
            format: 'dd/MM/yyyy HH:mm',
          },
        },
        grid: {
          show: true,
          borderColor: '#E5E5E5',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: false,
          colors: '#4F4F4F',
          offsetY: 0,
          offsetX: 0,
          markers: {
            width: 7,
            height: 7,
            radius: 7,
            offsetX: 0,
          },
        },
        yaxis: {
          forceNiceScale: false,
          decimalsInFloat: 2,
          min: 0,
          labels: {
            maxWidth: 40,
            formatter: value => this.transformUnit(value),
          },
        },
        xaxis: {
          type: 'datetime',
          categories: this.categories,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'dd/MM/yy',
              day: 'dd/MM/yyyy',
              hour: 'HH:mm',
            },
          },
        },
        responsive: [
          {
            breakpoint: 575.98,
            options: {
              chart: {
                height: 320,
                toolbar: {
                  show: false,
                },
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                floating: false,
                fontSize: '11px',
                colors: '#4F4F4F',
                markers: {
                  width: 7,
                  height: 7,
                  radius: 7,
                },
              },
              stroke: {
                width: 2,
              },
              yaxis: {
                labels: {
                  maxWidth: 40,
                  formatter: value => this.transformUnitWithoutDecimal(value),
                },
              },
              xaxis: {
                type: 'datetime',
                forceNiceScale: true,
                labels: {
                  show: true,
                  align: 'right',
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
    dateRange() {
      return _.get(this.query, 'range', null);
    },
    startDate() {
      return _.get(this.query, 'startDate', null);
    },
    endDate() {
      return _.get(this.query, 'endDate', null);
    },
  },
  methods: {
    abbreviateLongNumbers,
    transformUnit,
    transformDateToDisplay,
    transformUnitWithoutDecimal,
    numberWithCommas,
    getTimeString() {
      if (this.startDate && this.endDate) {
        return `${this.transformDateToDisplay(this.startDate)} - ${this.transformDateToDisplay(this.endDate)}`;
      }
      if (this.dateRange) {
        if (this.dateRange === 'last24hours') return 'Last 24 Hours';
        if (this.dateRange === 'last7days') return 'Last 7 Days';
        if (this.dateRange === 'last30days') return 'Last 30 Days';
      }
      return 'Last 24 Hours';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.time {
  font-size: $font-size-base * 1.25;
  color: #bdbdbd;
  padding-top: $spacer;
  padding-left: $spacer * 1.25;
}

.chart-container {
  max-height: 450px;
  padding: $spacer;
}

@media screen and (max-width: 575.98px) {
  .chart-container {
    padding: $spacer $spacer * 0.5;
  }

  .title {
    font-size: 16px;
  }

  ::v-deep .apexcharts-legend.position-top.apexcharts-align-left {
    left: 0 !important;
  }
}
</style>
