import _ from 'lodash';
import store from '../store';

const getStationDisplayName = (name) => {
  const stationList = _.get(store, 'state.user.stationList', null);

  if (_.isEmpty(stationList)) {
    return name;
  }

  const stationObject = _.find(stationList, station => station.stationName === name);

  if (_.isEmpty(stationObject)) {
    return name;
  }

  return _.get(stationObject, 'displayName', name);
};

export {
  // eslint-disable-next-line
  getStationDisplayName,
};
